// extracted by mini-css-extract-plugin
export var bg = "project-module--bg--YnLzy";
export var gridWrap = "project-module--gridWrap--LtAhI";
export var imgWrapper = "project-module--imgWrapper--PXjJN";
export var imge = "project-module--imge--GvGvL";
export var projectSection = "project-module--projectSection--8nTOP";
export var project_banner = "project-module--project_banner--gbYZj";
export var project_banner_bg_txt = "project-module--project_banner_bg_txt--HQtF0";
export var project_banner_content = "project-module--project_banner_content--2nPJd";
export var project_banner_content_header = "project-module--project_banner_content_header--X-yT2";
export var project_banner_content_text = "project-module--project_banner_content_text--bBHJP";
export var project_contents = "project-module--project_contents--FRpju";
export var project_link = "project-module--project_link--PAL88";
export var project_link_icon = "project-module--project_link_icon--aCB-a";
export var project_subTopic = "project-module--project_subTopic--k-wkR";
export var project_topic = "project-module--project_topic--mgSaQ";
export var project_wrapper = "project-module--project_wrapper--S06Fv";